
export default {
  name: 'FeePage',
  head() {
    return {
      title: "کارمزد ها",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "تتر اکسچنج | لیست کارمزد های وب سایت که کاربران برای خرید و فروش ارز پرداخت می نمایند."
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "کارمزد تتر اکسچنج, کارمزد رایگان, کارمزد خرید, کارمزد فروش"
        },
        { hid: "logo", name: "image", content: "~/layouts/logos/default.svg" },
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "googlebot", name: "googlebot", content: "index,follow" },
      ],
      link: [
        {
          hid: 'canonical',
          rel: "canonical",
          hreflang: "fa",
          href: 'https://tetherex.io/fee',
        },
      ],
    }
  },
  data() {
    return {
      userLevels: [],
    }
  },
  async fetch() {
    try {
      this.userLevels = await this.$axios.$get('/v1/wage');
    } catch (err) {
      this.$store.dispatch("messagePopup/error", err.response.data.message);
    }
  }
}
