import { render, staticRenderFns } from "./index.vue?vue&type=template&id=06c49a0d&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=06c49a0d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c49a0d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicTabBtnsFull: require('/app/components/public/TabBtnsFull.vue').default,PublicTetherBuy: require('/app/components/public/tether/Buy.vue').default,PublicTetherSell: require('/app/components/public/tether/Sell.vue').default,CorePrice: require('/app/components/core/Price.vue').default,PublicRowList: require('/app/components/public/RowList.vue').default,PublicTabBtns: require('/app/components/public/TabBtns.vue').default,CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,PublicSliderComments: require('/app/components/public/slider/Comments.vue').default,PublicSliderBlogPosts: require('/app/components/public/slider/BlogPosts.vue').default,CoreFaq: require('/app/components/core/Faq.vue').default})
